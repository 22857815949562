<template>
    <div>
        <div v-if="!expandedView && showing < post.commentObjs.filter(c => !c.deletedAt).length">
            <button type="button" class="inline-button" @click="showing = Infinity">
                View all comments
            </button>
        </div>

        <template v-for="comment in visibleComments">
            <hr :key="`${comment.id}-hr`" class="rule light">
            <comment :key="comment.id" :comment="comment" />
        </template>

        <hr class="rule">

        <comment-form v-if="currentUser" :post-id="post.id" :unique-id="uniqueId" />

        <div v-else class="text-center mt-4">
            <auth-button :redirect="postUrl" outlined>{{ $t('posts.logInToComment') }}</auth-button>
        </div>
    </div>
</template>

<script lang="ts">
import RouteNames from '@/router/names';
import { Comment, CurrentUser, Post } from '@/types';
import Vue from '@/vueTyped';
import orderBy from 'lodash/orderBy';
import AuthButton from '../AuthButton.vue';
import CommentComponent from './Comment.vue';
import CommentForm from './CommentForm.vue';

export default Vue.extend({
    components: {
        Comment: CommentComponent,
        CommentForm,
        AuthButton,
    },

    props: {
        post: {
            type: Object as () => Post,
            required: true,
        },

        expandedView: Boolean,

        uniqueId: {
            type: String,
            default: '',
        },
    },

    data: () => ({
        showing: 2,
        commentContent: '',
        saveInProgress: false,
    }),

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        visibleComments(): Comment[] {
            const showLast = this.expandedView ? 0 : (-1 * this.showing);
            return orderBy(this.post.commentObjs, 'observedAt').filter(c => !c.deletedAt).slice(showLast);
        },

        postUrl(): string {
            return this.$router.resolve({ name: RouteNames.POST_DETAIL, params: { id: this.post.id} }).route.path;
        },
    },

    methods: {
        async submitComment() {
            try {
                this.saveInProgress = true;

                const userCommentsBefore = this.post.commentObjs.filter(c => c.user === this.currentUser?.id);

                const { data } = await this.$store.dispatch('addCommentToPost', {
                    post: this.post,
                    user: this.currentUser,
                    comment: this.commentContent,
                    uniqueId: this.uniqueId
                });

                const userCommentsAfter = this.post.commentObjs.filter(c => c.user === this.currentUser?.id);

                const commentWasAdded = userCommentsAfter.length === userCommentsBefore.length + 1;

                if (!commentWasAdded) {
                    // We might not pick up the change if there's no `uniqueId`
                    // and we're not accessing the post through `$store.state.posts.selectedPost`.
                    // TODO: Find an alternative to `uniqueId` and `selectedPost`.
                    const myComments = data.comments.filter((comment: Comment) => comment.user === this.currentUser?.id);
                    const myNewComment = orderBy(myComments, 'createdAt', 'desc')[0];
                    myNewComment.userObj = this.currentUser;

                    // eslint-disable-next-line vue/no-mutating-props
                    this.post.commentObjs.push(myNewComment);
                }

                this.commentContent = '';

                this.showing += 1;
            } catch (error) {
                // TODO. Errors are handled globally for now.
            } finally {
                this.saveInProgress = false;
            }
        },
    }
});
</script>

<style lang="postcss" scoped>
.rule {
    border: 0 solid var(--color-medium);
    border-top-width: 1px;
    margin: var(--spacing-2) 0;
    opacity: 0.3;
}

.inline-button {
    color: var(--color-dark);
    font-weight: 500;
}

.new-comment-form {
    display: flex;
    margin-top: var(--spacing-3);
}

.new-comment-field {
    background: var(--color-light-tint);
    border-radius: var(--spacing-1);
    flex: 1 1 auto;
    min-height: 2.5em;
    padding: var(--spacing-2) var(--spacing-4);
    resize: none;
}
</style>
