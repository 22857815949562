<template>
    <section class="posts-list">
        <p v-if="!hideSearchDescription" class="mb-2 mx-10 text-center" :class="{'mt-6': $vuetify.breakpoint.xsOnly}" lang="en">
            {{ resultDescription }}

            <base-menu left>
                <template #activator="{ attrs, on }">
                    {{ $t('posts.sort.by').toLowerCase() }}

                    <base-button faux-link v-bind="attrs" style="color: inherit; text-decoration: none;" v-on="on">
                        {{ $t(`posts.sort.${currentSort}`).toLowerCase() }}
                        <v-icon>expand_more</v-icon>
                    </base-button>
                </template>

                <v-card>
                    <v-list-item v-for="sortKey in sortParams" :key="sortKey" :to="{ ...$route, query: { ...$route.query, sort: sortKey } }" exact>
                        {{ $t(`posts.sort.${sortKey}`) }}
                        <v-icon v-if="sortKey === currentSort" right>check</v-icon>
                    </v-list-item>
                </v-card>
            </base-menu>
        </p>

        <template v-if="posts.length === 0 && !postsLoaded">
            <post-card
                v-for="i in 10"
                :key="i"
                :post="{}"
                :loading="!postsLoaded"
                :expanded-view="false"
            />
        </template>

        <template v-for="(p, i) in (filteredByDate ? posts : postsWithDailyRollups)" v-else>
            <template v-if="!Array.isArray(p)">
                <span :key="`${p.id}-focus-point`" ref="focusPoints" tabindex="-1" />

                <post-card
                    :key="p.id"
                    :post="p"
                    :expanded-view="false"
                    :unique-id="uniqueId"
                />

                <base-button
                    v-if="!filteredByDate && Array.isArray(postsWithDailyRollups[i + 1])"
                    :key="`${p.id}-day-toggle`"
                    color="primary"
                    class="daily-rollup-toggle mb-2"
                    data-test-id="Show more posts button"
                    @click="$set(expandedFirstPostsOfTheDay, p.id, !expandedFirstPostsOfTheDay[p.id])"
                >
                    <template v-if="!expandedFirstPostsOfTheDay[p.id]">
                        Show
                    </template>
                    <template v-else>
                        Hide
                    </template>
                    {{ postsWithDailyRollups[i + 1].length }} more from {{ p.userObj.firstName }} on {{ p[sortProperty] | formatDate('LL') }}
                </base-button>
            </template>

            <!-- Try to match PostCard width: -->
            <transition-expand v-else :key="i" :value="expandedFirstPostsOfTheDay[postsWithDailyRollups[i - 1].id]" style="max-width: 500px; width: 100%;">
                <div>
                    <template v-for="subPost in p">
                        <span :key="`${subPost.id}-focus-point`" ref="focusPoints" tabindex="-1" />

                        <post-card
                            :key="subPost.id"
                            :post="subPost"
                            :expanded-view="false"
                            :unique-id="uniqueId"
                        />
                    </template>
                </div>
            </transition-expand>
        </template>

        <div v-if="posts.length === 0 && postsLoaded">
            <p class="mt-4">
                {{ $t('posts.noneFound') }}
                <router-link v-if="currentUser && filteredByLocation" :to="{ name: 'posts.create' }">{{ $t('posts.beTheFirst') }}</router-link>
                <router-link v-else-if="hasFiltersApplied" :to="{ query: {} }">{{ $t('posts.changeSearch') }}</router-link>
            </p>
        </div>

        <div v-if="posts.length > 0 && (pagination.page * pagination.perPageCount) < pagination.totalItems">
            <base-button class="mt-10 mb-12" color="primary" rounded :loading="loading" @click="loadMore()">{{ $t('posts.loadMore') }}</base-button>
        </div>

        <div v-if="posts.length > 0 && (pagination.page * pagination.perPageCount) > pagination.totalItems" class="text-center pa-8 grey--text text--darken-1">
            <p>{{ $t('posts.noMore') }}</p>
        </div>
    </section>
</template>

<script lang="ts">
// See virtual scroller for perf improvements
// https://github.com/Akryum/vue-virtual-scroller
import Vue from '@/vueTyped';
import { CurrentUser, Investigation, LngLat, MBAddressObj, Post, PostParameters, Region } from '@/types';
import get from 'lodash/get';
import PostCard from '@/components/PostCard.vue';
import { reverseGeocode } from '@/util.mapbox';
import moment from 'moment';
import partition from 'lodash/partition';
import { ISEECHANGE_TRENDS_USER_ID } from '@/config';
import { DEFAULT_DISTANCE_UNIT, POSTS_PER_PAGE, splitDistanceAndUnit } from '@/util.posts';
import TransitionExpand from './TransitionExpand.vue';

export default Vue.extend({
    components: {
        PostCard,
        TransitionExpand,
    },

    props: {
        uniqueId: {
            type: String,
            default: '',
        },

        userId: {
            type: String,
            default: '',
        },

        investigationId: {
            type: [
                String as () => Investigation['id'],
                Array as () => Investigation['id'][]
            ],
            default: '',
        },

        structuredQuestion: {
            type: Array as () => string[],
            default: () => [],
        },

        regionId: {
            type: String,
            default: '',
        },

        lngLat: {
            type: Array,
            default: null,
        },

        distance: {
            type: String,
            default: null,
        },

        fromDate: {
            type: String,
            default: '',
        },

        toDate: {
            type: String,
            default: '',
        },

        search: {
            type: String,
            default: '',
        },

        hideSearchDescription: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            locationFeatures: [] as MBAddressObj[],
            expandedFirstPostsOfTheDay: {} as Record<Post['id'], boolean>,
        };
    },
    computed: {
        sortParams(): string[] {
            return ['observed', 'created'];
        },

        currentSort(): string {
            const sortFromQuery = `${this.$route.query.sort}`;
            return this.sortParams.includes(sortFromQuery) ? sortFromQuery : 'observed';
        },

        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        currentRegion(): Region | null {
            return this.currentUser?.clientGroups?.flatMap(group => group.regions).find(region => region.id === this.regionId) ?? null;
        },

        filteredByLocation(): boolean {
            return Boolean((this.distance || this.lngLat) && !(this.fromDate || this.toDate || this.search));
        },

        hasFiltersApplied(): boolean {
            return Boolean(this.distance || this.lngLat || this.fromDate || this.toDate || this.search);
        },

        filteredByDate(): boolean {
            return Boolean(this.toDate || this.fromDate);
        },

        postsLoaded(): boolean {
            return this.$store.state.posts.asyncStatus === 'success';
        },

        sortProperty(): 'observedAt' | 'createdAt' {
            return this.currentSort === 'observed' ? 'observedAt' : 'createdAt';
        },

        posts(): Post[] {
            const now = moment();
            const posts = get(this.$store.state.posts.byUniqueId[this.uniqueId], 'items', [])
                .filter(post => now.isAfter(post.observedAt)) // Don't render future-observed posts that might get through.
                .sort((postA, postB) => {
                    return postA[this.sortProperty] > postB[this.sortProperty] ? -1 : postA[this.sortProperty] < postB[this.sortProperty] ? 1 : 0;
                });

            if (!this.filteredByDate) { // If we query for a specific time, let's not change the order.
                const stickTrendsPostsSince = new Date(new Date().setDate(new Date().getDate() - 21));
                const [ trendsPosts, otherPosts ] = partition(posts, (post) => {
                    const isTrendsPost = post.userObj.id === ISEECHANGE_TRENDS_USER_ID;
                    const andIsInFirstBatch = isTrendsPost && posts.indexOf(post) < POSTS_PER_PAGE;
                    const andIsRecentEnough = andIsInFirstBatch && new Date(post.observedAt) > stickTrendsPostsSince;
                    return andIsRecentEnough;
                });
                return [ ...trendsPosts, ...otherPosts ];
            } else {
                return posts;
            }
        },

        postsWithDailyRollups(): (Post | Post[])[] {
            const results: (Post | Post[])[] = [];

            for (const post of this.posts) {
                const indexOfUsersFirstPostOfTheDay = results.findIndex(resultsPost => {
                    return (
                        !Array.isArray(resultsPost) &&
                        resultsPost.user === post.user &&
                        resultsPost[this.sortProperty].split('T')[0] === post[this.sortProperty].split('T')[0]
                    );
                });

                if (indexOfUsersFirstPostOfTheDay !== -1) {
                    if (!Array.isArray(results[indexOfUsersFirstPostOfTheDay + 1])) {
                        results.splice(indexOfUsersFirstPostOfTheDay + 1, 0, []);
                    }
                    const collapsedPosts = results[indexOfUsersFirstPostOfTheDay + 1] as Post[];
                    collapsedPosts.push(post);
                } else {
                    results.push(post);
                }
            }

            return results;
        },

        investigations(): Investigation[] {
            return this.$store.state.investigations.items;
        },

        requestParams(): PostParameters {
            return {
                uniqueId: this.uniqueId,
                userId: this.userId,
                investigationId: this.investigationId,
                structuredQuestion: this.structuredQuestion,
                regionId: this.regionId,
                lngLat: this.lngLat as LngLat ?? undefined,
                distance: this.distance || undefined,
                fromDate: this.fromDate ? this.fromDate : '',
                toDate: this.toDate ? this.toDate : '',
                search: this.search,
                sortBy: { created: 'createdAt', observed: 'observedAt' }[this.currentSort],
            };
        },

        pagination(): any {
            if (this.$store.state.posts.byUniqueId[this.uniqueId]) {
                return this.$store.state.posts.byUniqueId[this.uniqueId].pagination || {};
            }

            return {};
        },

        resultDescription(): string {
            // TODO: Refactor and translate this.
            const res = [];
            if (this.currentRegion) {
                res.push(`from ${this.currentRegion.label}`);
            } else if (this.distance && this.locationFeatures.length !== 0) {
                let { distance, unit } = splitDistanceAndUnit(this.distance);
                unit ??= DEFAULT_DISTANCE_UNIT;
                const distanceText = this.$tc(`posts.searchDialog.distanceIn.${unit}`, distance);

                const firstNonPoi = this.locationFeatures.find(feature => !feature.place_type.includes('poi'));

                if (firstNonPoi) {
                    let locationText = firstNonPoi.place_name;

                    const postCode = firstNonPoi.context?.find(context => context.id.startsWith('postcode'));

                    if (postCode) {
                        locationText = locationText.split(postCode.text)[0].trim();
                    }

                    if (firstNonPoi.place_type.includes('address')) {
                        res.push(`within ${distanceText} of ${locationText}`);
                    } else {
                        res.push(`from ${locationText} (around ${distanceText})`);
                    }
                }
            } else {
                res.push('from around the world');
            }

            if (this.investigationId) {
                const investigationIds = Array.isArray(this.investigationId) ? this.investigationId : [this.investigationId];
                const investigations = this.investigations.filter((i) => investigationIds.includes(i.id));
                const investigationNames = investigations.map(i => i.name);
                if (investigations.length !== 0) {
                    res.push(`relating to ${investigationNames.join(' or ')}`);
                }
            }

            const fromDateStr = this.$options.filters?.formatDate(this.fromDate);
            const toDateStr = this.$options.filters?.formatDate(this.toDate);

            if (fromDateStr && toDateStr) {
                if (fromDateStr === toDateStr) {
                    res.push(`on ${fromDateStr}`);
                } else {
                    res.push(`between ${fromDateStr} and ${toDateStr}`);
                }
            } else if (this.fromDate || this.toDate) {
                res.push(`${this.fromDate ? 'after' : 'before'} ${fromDateStr || toDateStr}`);
            }

            if (this.search) {
                const terms = this.search.split(' ').map( a => `“${a}”`);
                const searchList = terms.slice(0, terms.length - 1).join(', ') + ` and ${terms[terms.length - 1]}`;
                res.push(`containing ${searchList}`);
            }

            return `Showing sightings ${res.join(', ')}`;
        },
    },

    watch: {
        requestParams: {
            deep: true,
            handler()  {
                this.fetchPosts();
            },
        },

        lngLat: {
            immediate: true,
            async handler(value) {
                if (value) {
                    const reverseGeocodeCoords = await reverseGeocode(value, { limit: 1, types: undefined });
                    this.locationFeatures = reverseGeocodeCoords.features;
                } else {
                    this.locationFeatures = [];
                }
            },
        },
    },

    mounted() {
        this.fetchPosts();
    },

    methods: {
        async fetchPosts() {
            this.loading = true;
            await this.$store.dispatch('fetchPosts', this.requestParams);
            this.loading = false;
        },

        async loadMore() {
            const firstNewPostIndex = (this.$refs.focusPoints as HTMLSpanElement[]).length;

            this.loading = true;

            await this.$store.dispatch('fetchMorePosts', {
                params: this.requestParams,
                pagination: {
                    ...this.pagination,
                    page: this.pagination.page + 1,
                },
            });

            this.loading = false;

            await this.$nextTick();

            const firstNewPostCard = (this.$refs.focusPoints as HTMLSpanElement[])[firstNewPostIndex];
            (firstNewPostCard as HTMLDivElement)?.focus();
        },
    },
});
</script>

<style lang="postcss" scoped>
.posts-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.daily-rollup-toggle {
    height: auto !important;
    padding-block: 0.7em !important;
}

.daily-rollup-toggle :deep(.v-btn__content) {
    display: block !important;
    flex-shrink: 1 !important;
    white-space: normal !important;
}
</style>
