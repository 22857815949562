<template>
    <div :class="{ 'new-design': newDesign }">
        <ul
            class="images"
            :data-count="value.length"
            @dragover="handleDrag($event)"
            @dragleave="dropIndex = null"
            @drop="handleFileDrop"
        >
            <li v-if="dropIndex === 0" class="drop-indicator" role="presentation" />

            <template v-for="(item, i) in value">
                <li
                    :key="identify(item)"
                    class="drag-handle"
                    :data-index="i"
                    :data-being-dragged="item === itemBeingDragged"
                    @touchmove="$event.preventDefault()"
                >
                    <div class="preview-container">
                        <universal-image
                            class="preview"
                            :src="item"
                            :alt="`${i + 1}/${value.length}`"
                            tabindex="0"
                            draggable="false"
                            @pointerdown="startDragging(item, $event)"
                            @keydown.left="move(item, i - 1)"
                            @keydown.right="move(item, i + 2)"
                            @keydown.delete="remove(item)"
                        />

                        <button type="button" class="remove-button" :aria-label="`Remove image ${i}`" @click="remove(item)">
                            <v-icon style="color: inherit; font-size: inherit;">delete</v-icon>
                        </button>
                    </div>
                </li>

                <li v-if="dropIndex === i + 1" :key="`${i}+1 drop-indicator`" class="drop-indicator" role="presentation" />
            </template>

            <li
                v-if="accept !== ''"
                :data-index="value.length"
                @touchmove="$event.preventDefault()"
            >
                <button type="button" class="add-button" @click="handleAddClick">
                    <v-icon>add</v-icon>
                </button>
            </li>

        </ul>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import UniversalImage from '@/components/UniversalImage.vue';
import { Photo } from '@/types';
import { openFilePicker } from '@/util.app';

type AcceptableImage = File | Photo;

export default Vue.extend({
    components: {
        UniversalImage,
    },

    props: {
        value: {
            type: Array as () => AcceptableImage[],
            default: () => [],
        },

        accept: {
            type: String,
            default: '',
        },

        max: {
            type: Number,
            default: Infinity,
        },

        newDesign: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            itemBeingDragged: null as AcceptableImage | null,
            dropIndex: null as number | null,
        };
    },

    methods: {
        identify(item: AcceptableImage): string {
            if (item instanceof File) {
               return `${item.lastModified} ${item.name} ${item.size} ${item.type}`;
            } else {
                return item.id;
            }
        },

        add(incoming: AcceptableImage[], index: number = Infinity) {
            incoming = incoming.filter(incomingFile => {
                return !this.value.some(existingFile => this.identify(existingFile) === this.identify(incomingFile));
            });

            const newValue = Array.from(this.value);
            newValue.splice(index, 0, ...incoming);
            this.emitInput(newValue.slice(0, this.max));
        },

        move(item: AcceptableImage, newIndex: number) {
            const newValue = Array.from(this.value);
            const oldIndex = newValue.indexOf(item);
            newValue.splice(oldIndex, 1);
            if (oldIndex < newIndex) {
                newIndex -= 1;
            }
            newIndex = Math.min(Math.max(0, newIndex), this.value.length - 1);
            newValue.splice(newIndex, 0, item);
            this.emitInput(newValue);
        },

        remove(item: AcceptableImage) {
            const newValue = Array.from(this.value);
            const index = newValue.indexOf(item);
            newValue.splice(index, 1);
            this.emitInput(newValue);
        },

        startDragging(item: AcceptableImage, event: PointerEvent) {
            event.preventDefault();

            this.itemBeingDragged = item;

            const document = (event.target as HTMLElement).ownerDocument;
            document.addEventListener('pointermove', this.handleDrag);
            document.addEventListener('pointerup', this.handleRelease);
            document.addEventListener('keydown', this.handleRelease);
        },

        handleDrag(event: DragEvent | PointerEvent) {
            type DropTarget = HTMLElement & { dataset: { index: string } } | null;

            const document = (event.target as HTMLElement).ownerDocument;
            const topElement = document.elementFromPoint(event.x, event.y);
            const dropTarget = topElement?.closest('.images > [data-index]') as DropTarget;

            if (dropTarget) {
                const rect = dropTarget.getBoundingClientRect();
                const left = event.x < rect.left + rect.width / 2;
                this.dropIndex = parseFloat(dropTarget.dataset.index) + (left ? 0 : 1);
            } else if (this.$el.contains(topElement)) {
                this.dropIndex = this.value.length;
            } else {
                this.dropIndex = null;
            }
        },

        handleRelease(event: PointerEvent | KeyboardEvent) {
            let cancel = false;

            if (event.type === 'keydown') {
                if ((event as KeyboardEvent).key === 'Escape') {
                    cancel = true;
                } else {
                    return;
                }
            }

            const document = (event.target as HTMLElement).ownerDocument;
            document.removeEventListener('pointermove', this.handleDrag);
            document.removeEventListener('pointerup', this.handleRelease);
            document.removeEventListener('keydown', this.handleRelease);

            if (!cancel && this.itemBeingDragged && this.dropIndex !== null) {
                this.move(this.itemBeingDragged, this.dropIndex);
            }

            this.itemBeingDragged = null;
            this.dropIndex = null;
        },

        async handleAddClick() {
            const selectedFiles = await openFilePicker(this.accept, true, true);
            this.$emit('input', [...this.value, ...selectedFiles]);
        },

        handleFileDrop(event: DragEvent) {
            event.preventDefault();

            const files = event.dataTransfer?.files ?? [];
            const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));

            if (imageFiles.length !== 0 && this.dropIndex !== null) {
                this.add(imageFiles, this.dropIndex);
            }

            this.dropIndex = null;
        },

        async emitInput(newValue: AcceptableImage[]) {
            const focusedElement = this.$el.ownerDocument.activeElement;
            this.$emit('input', newValue);
            await this.$nextTick();
            (focusedElement as HTMLElement | null)?.focus();
        },
    },
});
</script>

<style lang="postcss" scoped>
.images {
    --internal-height: var(--image-preview-height, 100px);
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: -5px;
    padding: 0;
    user-select: none;
}

.images[data-count="0"] {
    justify-content: center;
}

.drop-indicator {
    display: inline-block;
    height: var(--internal-height);
    pointer-events: none;
    position: relative;
    width: 0;
}

.drop-indicator::before {
    background: currentColor;
    border-radius: 3px;
    content: '';
    height: 100%;
    left: -1px;
    position: absolute;
    right: -1px;
    top: 0;
}

.drop-indicator:-moz-drag-over {
    background: green;
}

.drag-handle {
    cursor: move;
    padding: 5px;
}

.drag-handle[data-being-dragged] {
    opacity: 0.6;
}

.preview-container {
    position: relative;
}

.preview {
    background: #8883;
    border: 3px solid white;
    box-shadow: 0 2px 5px #0004;
    display: block;
    height: var(--internal-height);
}

.new-design .preview {
    aspect-ratio: 1 / 1;
    border: 0;
    border-radius: 8px;
    box-shadow: none;
}

.preview:not([src]) {
    background: gray;
    opacity: 0.5;
    width: var(--internal-height);
}

.remove-button {
    background: white;
    border-radius: 4px;
    color: inherit;
    font-size: 1.25rem;
    height: 1.5rem;
    line-height: 0;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 1.5rem;
}

.add-button {
    border: 2px dashed #E1E7ED;
    border-radius: 8px;
    color: #0172DA;
    height: var(--internal-height);
    margin-left: 5px;
    width: var(--internal-height);
}
</style>
