<template>
  <div v-if="!loading" class="post-card" :data-created="post.createdAt" data-test-id="Post card">
    <div class="sighting-author" :class="{ 'trends': isTrendsPost }">
        <v-lazy>
            <component :is="currentUser ? 'router-link' : 'span'" :to="{ name: 'user.detail', params: { id: post.userObj.id } }" aria-hidden="true" tabindex="-1">
                <img v-if="post.userObj.avatar" :src="post.userObj.avatar | formatImage(48, 48)" alt="" class="author-avatar" width="48" height="48">
            </component>
        </v-lazy>

        <div lang="en">
            <component :is="currentUser ? 'router-link' : 'span'" :to="{ name: 'user.detail', params: { id: post.userObj.id } }" class="author-name">
                {{ post.userObj.firstName }} {{ currentUser ? post.userObj.lastName : post.userObj.lastName.slice(0, 1) }}
            </component>

            <div v-if="post.userObj.clientGroups && post.userObj.clientGroups.length > 0">
                {{ post.userObj.clientGroups[0].name }}
            </div>
        </div>

        <v-spacer />

        <base-menu left>
            <template #activator="{ attrs, on }">
                <button v-bind="attrs" data-test-id="More menu button" v-on="on">
                    <v-icon>more_horiz</v-icon>
                    <span class="d-sr-only">{{ $t('actions.showMore') }}</span>
                </button>
            </template>

            <v-card data-test-id="More menu">
                <v-list-item v-if="!expandedView" :to="{ name: 'posts.detail', params: { id: post.id } }">
                    <v-list-item-title>{{ $t('posts.view') }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="canDownloadZip" @click="downloadZip">
                    <v-list-item-title>{{ $t('posts.downloadZip') }}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="showShare">
                    <v-list-item-title>{{ $t('posts.share') }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="!isOwnPost" @click="reportModalOpen = true">
                    <v-list-item-title>{{ $t('posts.report') }}</v-list-item-title>
                </v-list-item>

                <template v-if="isOwnPost">
                    <v-divider />

                    <v-list-item v-if="!isBeingEdited" @click="toggleEditing">
                        <v-list-item-title>{{ $t('posts.edit') }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-else @click="toggleEditing">
                        <v-list-item-title>{{ $t('posts.cancelEdit') }}</v-list-item-title>
                    </v-list-item>

                    <v-dialog v-model="showingDeleteDialog" width="40ch">
                        <template #activator="{ attrs, on }">
                            <v-list-item v-bind="attrs" data-test-id="Delete button" v-on="on">
                                <v-list-item-title>{{ $t('posts.delete') }}</v-list-item-title>
                            </v-list-item>
                        </template>

                        <v-card data-test-id="Confirm post delete dialog">
                            <v-card-title role="heading" aria-level="2">{{ $t('posts.deleteDialog.title') }}</v-card-title>

                            <v-card-text>
                                <p>{{ $t('posts.deleteDialog.warning') }}</p>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer />
                                <base-button text @click="showingDeleteDialog = false">{{ $t('posts.deleteDialog.cancel') }}</base-button>
                                <base-button text color="error" :loading="isBeingDeleted" data-test-id="Confirm delete button" @click="deletePost">{{ $t('posts.deleteDialog.confirm') }}</base-button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-card>
        </base-menu>
    </div>

    <div class="sighting-metadata">
        <div>
            <v-icon class="location-icon">location_on</v-icon>
            <span class="d-sr-only">{{ $t('posts.location') }}</span>
            {{ location }}
        </div>

        <div>
            <span class="d-sr-only">{{ $t('posts.date') }}</span>
            <time :datetime="post.observedAt" :title="new Date(post.observedAt).toLocaleString()">{{ post.observedAt | formatDate('MMM DD, YYYY') }}</time>
        </div>
    </div>

    <view-detector :post-id="post.id" class="post-card__main">
        <slippy-carousel
            v-if="sortedMedia.length !== 0 || post.showSatelliteImage"
            :prev-next-arrows="sortedMedia.length + (post.showSatelliteImage ? 1 : 0) > 1"
            floating-arrows
            style="aspect-ratio: 1;"
        >
            <v-lazy v-for="(photo, i) in sortedMedia" :key="photo.id">
                <universal-image
                    :src="photo"
                    alt=""
                    :tab-index="0"
                    style="aspect-ratio: 1; display: block; height: auto; width: 100%;"
                    @click="() => (photo.additionalInfo && photo.additionalInfo.mux) ? null : openGallery(i)"
                />
            </v-lazy>
            <div v-if="post.showSatelliteImage">
                <universal-image
                    :src="getSatelliteImage(post)"
                    alt="Satellite image"
                    :tab-index="0"
                    style="aspect-ratio: 1; display: block; height: auto; width: 100%;"
                    @click="openGallery(sortedMedia.length)"
                />
            </div>
        </slippy-carousel>

        <v-dialog v-model="gallery.open" fullscreen>
            <slippy-carousel
                ref="gallery"
                class="gallery"
                :prev-next-arrows="sortedMedia.length + (post.showSatelliteImage ? 1 : 0) > 1"
                floating-arrows
                style="background: #8888; bottom: 0; left: 0; position: absolute; right: 0; top: 0;"
            >
                <v-lazy v-for="photo in sortedMedia" :key="photo.id" style="align-items: center; display: flex; justify-content: center;">
                    <universal-image
                        :src="photo"
                        alt=""
                        class="gallery-image"
                    />
                </v-lazy>
                <div v-if="post.showSatelliteImage" style="align-items: center; display: flex; justify-content: center;">
                    <universal-image
                        :src="getSatelliteImage(post, 1000, 1000)"
                        alt="Satellite image"
                        class="gallery-image"
                        @click="openGallery(sortedMedia.length)"
                    />
                </div>
            </slippy-carousel>

            <base-button fab style="position: absolute; right: 1rem; top: 1rem;" @click="gallery.open = false">
                <v-icon>close</v-icon>
            </base-button>
        </v-dialog>

        <v-expansion-panels v-if="structuredDataFromUser.length !== 0 || post.weatherData.currently">
            <v-expansion-panel>
                <v-expansion-panel-header class="px-4 mb-n4">
                    <template #default="{ open }">
                        <div class="post-data-title">
                            <template v-if="post.weatherData.currently">
                                <v-icon v-if="abnormalWeather" small color="var(--color-danger)" class="icon">warning</v-icon>
                                <v-icon small color="primary" class="icon">device_thermostat</v-icon>
                                {{ post.weatherData.currently.temperature }} {{ post.weatherUnits.temperature }}
                                <v-icon small color="primary" class="icon" :style="`transform: rotate(${ open ? '-180deg' : '0deg'});`">
                                    expand_more
                                </v-icon>
                            </template>

                            <span class="details-toggle">
                                {{ $tc('posts.details', structuredDataFromUser.length) }}
                            </span>
                        </div>
                    </template>

                    <template #actions>
                        <span />
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content eager class="contextual-data mt-3">
                    <v-tabs fixed-tabs>
                        <v-tab v-if="structuredDataFromUser.length !== 0">
                            {{ $t('posts.promotedDataDevice') }}
                        </v-tab>

                        <v-tab v-if="post.weatherData.currently">
                            {{ $t('posts.weather') }}
                        </v-tab>

                        <v-tab-item v-if="structuredDataFromUser.length !== 0">
                            <div class="tab-content">
                                <table class="basic-table">
                                    <tbody>
                                        <tr v-for="question in structuredDataFromUser" :key="question.id">
                                            <th>{{ question.title }}</th>
                                            <td>
                                                <template v-for="(line, i) in [].concat(getAnswerVal(question, question.answer))">
                                                    <div :key="i">{{ line }}</div>
                                                </template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-tab-item>

                        <v-tab-item v-if="post.weatherData.currently">
                            <div class="tab-content">
                                <template v-if="abnormalWeather">
                                    <overlay-modal v-model="abnormalWeatherDialogOpen" no-card max-width="600px">
                                        <modal-content :location="post.addressComponents" :analysis="abnormalWeather" no-cta />
                                    </overlay-modal>

                                    <p style="color: var(--color-danger);">
                                        <deviation-description :analysis="abnormalWeather" no-asterisk />
                                        <base-button faux-link @click="abnormalWeatherDialogOpen = true">Learn about the data</base-button>
                                    </p>
                                </template>

                                <p>
                                    <small class="data-description-text">
                                        {{ $t('posts.weatherNote', {
                                            location: roughLocation,
                                            date: $options.filters.formatDate(post.observedAt, 'MMM DD YYYY, hh:mm A')
                                        }) }}
                                    </small>
                                </p>

                                <table class="basic-table">
                                    <tbody>
                                        <!-- https://darksky.net/dev/docs#response-format -->
                                        <tr v-if="post.weatherData.currently.summary">
                                            <th>{{ $t('posts.weatherDetails.summary') }}</th>
                                            <td>{{ post.weatherData.currently.summary }}</td>
                                        </tr>
                                        <tr v-if="post.weatherData.currently.apparentTemperature || post.weatherData.currently.apparentTemperature === 0">
                                            <th>{{ $t('posts.weatherDetails.apparentTemperature') }}</th>
                                            <td>{{ post.weatherData.currently.apparentTemperature }}{{ post.weatherUnits.apparentTemperature }}</td>
                                        </tr>
                                        <tr v-if="post.weatherData.currently.cloudCover || post.weatherData.currently.cloudCover === 0">
                                            <th>{{ $t('posts.weatherDetails.cloudCoverage') }}</th>
                                            <td>{{ post.weatherData.currently.cloudCover | percent }}</td>
                                        </tr>
                                        <tr v-if="post.weatherData.currently.humidity || post.weatherData.currently.humidity === 0">
                                            <th>{{ $t('posts.weatherDetails.humidity') }}</th>
                                            <td>{{ post.weatherData.currently.humidity | percent }}</td>
                                        </tr>
                                        <tr v-if="weatherRecently.precip || weatherRecently.precip === 0">
                                            <th>{{ $t('posts.weatherDetails.precip') }}</th>
                                            <td>{{ weatherRecently.precip }} {{ post.weatherUnits.recently.precip }}</td>
                                        </tr>
                                        <tr v-if="weatherRecently.snow"><!--Hidden if zero.-->
                                            <th>{{ $t('posts.weatherDetails.snow') }}</th>
                                            <td>{{ weatherRecently.snow }} {{ post.weatherUnits.recently.snow }}</td>
                                        </tr>
                                        <tr v-if="post.weatherData.currently.dewPoint || post.weatherData.currently.dewPoint === 0">
                                            <th>{{ $t('posts.weatherDetails.dewPoint') }}</th>
                                            <td>{{ post.weatherData.currently.dewPoint }}{{ post.weatherUnits.dewPoint }}</td>
                                        </tr>
                                        <tr v-if="post.weatherData.currently.pressure || post.weatherData.currently.pressure === 0">
                                            <th>{{ $t('posts.weatherDetails.pressure') }}</th>
                                            <td>{{ post.weatherData.currently.pressure }} {{ post.weatherUnits.pressure }}</td>
                                        </tr>
                                        <tr v-if="post.weatherData.currently.windBearing || post.weatherData.currently.windBearing === 0">
                                            <th>{{ $t('posts.weatherDetails.windBearing') }}</th>
                                            <td>{{ post.weatherData.currently.windBearing }}{{ post.weatherUnits.windBearing }}</td>
                                        </tr>
                                        <tr v-if="post.weatherData.currently.windSpeed || post.weatherData.currently.windSpeed === 0">
                                            <th>{{ $t('posts.weatherDetails.windSpeed') }}</th>
                                            <td>{{ post.weatherData.currently.windSpeed }} {{ post.weatherUnits.windSpeed }}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <small class="data-description-text">{{ $t('posts.weatherDetails.source') }} {{ post.weatherDataType }}</small>
                            </div>
                        </v-tab-item>
                    </v-tabs>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <div v-if="post.textBody" class="my-4 px-4">
            <template v-if="isBeingEdited">
                <auto-height-text-area ref="editField" v-model="editableText" class="edit-field" />
            </template>

            <markdown-output v-else :id="`${post.id}-description`" :value="post.textBody" basic-formatting contain-margins lang="" />

            <transition-expand v-if="post.updatedAt > post.createdAt" :value="!isBeingEdited">
                <small style="color: var(--color-dark);">
                    {{ $t('posts.edited', { date: $options.filters.formatDateAndTime(post.updatedAt) }) }}
                </small>
            </transition-expand>

            <transition-expand :value="isBeingEdited">
                <div class="mt-2">
                    <base-button color="primary" :loading="saveInProgress !== 0" @click="saveEdits">Save</base-button>
                    <base-button text @click="isBeingEdited = false">Cancel</base-button>
                </div>
            </transition-expand>
        </div>

        <div v-if="post.weird" class="unusual-indicator px-4 mb-2">{{ $t('posts.isUnusual') }}</div>

        <div v-if="currentUser && sortedInvestigations.length !== 0" class="investigation px-4">
            <template v-for="(investigation, i) in sortedInvestigations">
                <router-link :key="investigation.id" class="investigation-label" :to="{ name: 'investigations.detail.description', params: { id: investigation.id } }">
                    <span class="d-sr-only">{{ $t('posts.topics') }}:</span>
                    <span lang="en">{{ investigation.name }}</span>
                </router-link>
                <template v-if="i < sortedInvestigations.length - 1">{{ investigationsSeparator }} </template>
            </template>
        </div>
    </view-detector>

    <post-comments
        :post="post"
        :expanded-view="expandedView"
        :unique-id="uniqueId"
        class="ma-4"
    />

    <post-card-report-modal v-if="reportModalOpen" :post-id="post.id" @dismiss="reportModalOpen = false" />
  </div>

  <v-card v-else class="post-card">
      <v-skeleton-loader type="list-item-avatar-two-line" />
      <v-skeleton-loader type="image" />
      <v-skeleton-loader type="paragraph" class="pa-4" />
  </v-card>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { CurrentUser, Investigation, Post, StructuredAnswer, StructuredQuestion, Photo, WeatherDataRecently } from '../types';
import flatMap from 'lodash/flatMap';
import moment from 'moment';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import PostComments from '@/components/PostComments/Index.vue';
import RouteNames from '@/router/names';
import orderBy from 'lodash/orderBy';
import { ISEECHANGE_TRENDS_USER_ID } from '@/config';
import { longAddressFromComponents } from '@/util.location';
import { makeSatelliteImageSrc } from '@/util.mapbox';
import AutoHeightTextArea from './AutoHeightTextArea.vue';
import TransitionExpand from './TransitionExpand.vue';
import ViewDetector from './ViewDetector.vue';
import DeviationDescription from '@/components/HistoricalWeatherPrompt/DeviationDescription.vue';
import tidyWeatherAnalysis, { TidyWeatherAnalysis } from '@/util.tidy-weather-analysis';
import { downloadZip } from '@/util.posts';
import OverlayModal from '@/layouts/OverlayModal.vue';
import ModalContent from './HistoricalWeatherPrompt/ModalContent.vue';
import PostCardReportModal from './PostCardReportModal.vue';
import SlippyCarousel from './SlippyCarousel.vue';
import UniversalImage from './UniversalImage.vue';

export default Vue.extend({
    name: 'PostCard',
    components: {
        PostComments,
        MarkdownOutput,
        AutoHeightTextArea,
        TransitionExpand,
        ViewDetector,
        DeviationDescription,
        OverlayModal,
        ModalContent,
        PostCardReportModal,
        SlippyCarousel,
        UniversalImage,
    },
    props: {
        post: {
            type: Object as () => Post,
            required: true,
        },
        // Shows comments, data expanded.
        expandedView: Boolean,
        loading: {
            type: Boolean,
            default: false,
        },
        uniqueId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            gallery: {
                open: false,
                index: 0,
            },
            dataTabIndex: 0,
            abnormalWeatherDialogOpen: false,
            showComments: false,
            isBeingEdited: false,
            editableText: '',
            saveInProgress: 0,
            reportModalOpen: false,
            showingDeleteDialog: false,
            isBeingDeleted: false,
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
        roughLocation(): string {
            return longAddressFromComponents(this.post.addressComponents);
        },
        location(): string {
            const { text, streetNumber, streetName, city, place, state, zipcode } = this.post.addressComponents;
            if (text) {
                return text.split(`, ${state} ${zipcode}`)[0];
            } else if (streetNumber) {
                return `${streetNumber} ${streetName}, ${city || place}`;
            } else {
                return this.roughLocation;
            }
        },
        weatherRecently(): WeatherDataRecently | {} {
            const TIME_RANGE = 12;
            return this.post.weatherData.currently?.recently?.find(entry => entry.lastXHours === TIME_RANGE) ?? {};
        },
        abnormalWeather(): TidyWeatherAnalysis | null {
            if (this.post.weatherAnalyticsData) {
                const tidyData = tidyWeatherAnalysis(this.post.weatherAnalyticsData);
                if (tidyData.highDeviation > 1 || tidyData.lowDeviation > 1) {
                    return tidyData;
                }
            }
            return null;
        },
        sortedMedia(): (Photo | any)[] {
            return [
                ...orderBy(this.post.media, 'created_at'),
                // { id: 'TESTING', additionalInfo: { mux: { playback_id: 'zwiaIfCf7100o01HUZ86cx6pgE00yUdbc9DcNfbZGsFSDA' } } },
            ];
        },
        sortedInvestigations(): Investigation[] {
            const storeInvestigations = this.$store.state.investigations.items;
            const postInvestigations = this.post.investigations.map(id => storeInvestigations.find(i => i.id === id));
            const presentInvestigations = postInvestigations.filter(Boolean) as Investigation[];
            return orderBy(presentInvestigations, 'name');
        },
        isOwnPost(): boolean {
            return this.currentUser?.id === this.post?.user;
        },
        canDownloadZip(): boolean {
            return !this.$vuetify.breakpoint.smAndDown && Boolean(this.post.showSatelliteImage);
        },
        isTrendsPost(): boolean {
            return this.post.userObj.id === ISEECHANGE_TRENDS_USER_ID
        },
        showExpandedContents(): boolean {
            return this.expandedView;
        },
        structuredDataFromUser(): StructuredQuestion[] {
            const questions = flatMap(this.$store.state.investigations.items, (i: Investigation) => i.structuredQuestions);
            if (this.post.structuredAnswers) {
                return (this.post.structuredAnswers.map((a) => ({
                    id: null,
                    ...questions.find((q) => q.id === a.questionId),
                    answer: a,
                }))
                .filter((q) => q.id) as StructuredQuestion[])
                .sort((q1: StructuredQuestion, q2: StructuredQuestion) => q1.order - q2.order) ;
            }
            return [];
        },
        promotedQuestion(): StructuredQuestion | null {
            if (this.structuredDataFromUser.length) {
                return this.structuredDataFromUser.filter((a) => a.answer && a.answer.promotedQuestion)[0] || null;
            }
            return null;
        },
        investigationsSeparator(): string {
            const allNames = this.sortedInvestigations.map(investigation => investigation.name);
            return allNames.join(' ').includes(',') ? ';' : ',';
        },
    },
    watch: {
        'post.textBody': {
            immediate: true,
            handler(textBody) {
                this.editableText = textBody;
            },
        }
    },
    methods: {
        getSatelliteImage(post: Post, width = 500, height = 500, zoom = 17) {
            return makeSatelliteImageSrc({
                center: [post.lng, post.lat],
                pin: [post.lng, post.lat],
                zoom,
                width,
                height,
            });
        },
        downloadZip() {
            try {
                downloadZip(this.post.id);
            } catch (error) {
                console.error(error);
            }
        },
        cleanDecimal(n: number, places: number) {
            const fixed = n.toFixed(places);
            return places === 0 ? fixed : fixed.replace(/0+$/, '').replace(/\.$/, '');
        },
        getAnswerVal(q: StructuredQuestion | null, a: StructuredAnswer | null): string | string[] {
            if (!q || !a) {
                return '';
            }
            let value: string | string[] = a.literalValue ?? '';
            let unit = '';
            switch (q.dataType) {
                case 'DECIMAL':
                case 'NUMERIC':
                    unit = q.unit.toLowerCase();
                    if (unit === 'inches' || unit == 'mm') {
                        value = this.cleanDecimal(a.numericValue ?? 0, 4);
                        if (unit === 'mm' && a.numericValue && a.numericValue > 10) {
                            value = this.cleanDecimal((a.numericValue ?? 0) / 10, 4);
                            unit = 'cm';
                        }
                    } else {
                        value = this.cleanDecimal(a.numericValue ?? 0, 4);
                    }
                    break;
                case 'DATETIME':
                    value = moment(`${a.datetimeValue}`).format('hh:mm A');
                    break;
                case 'BOOLEAN':
                    value = a.booleanValue ? 'yes' : 'no';
                    unit = '';
                    break;
                case 'TIMERANGE':
                    value = (a.timeRangeValue ?? []).map(dateString => dateString ? moment(dateString).format('l LT') : '?').join('–');
                    break;
                case 'SELECT':
                    if (q.additionalInfo.multiple) {
                        try {
                            value = JSON.parse(value);
                        } catch (error) {
                            console.warn(`Couldn't parse SELECT/multiple answer ${value}`);
                        }
                    }
                    break;
                default:
                    break;
            }
            if (Array.isArray(value)) {
                value = value.join(', ');
            }
            return `${value} ${unit}`.trim();
        },
        async openGallery(index: number) {
            this.gallery.open = true;
            await this.$nextTick();
            (this.$refs.gallery as any)?.scrollTo(index);
        },
        showShare() {
            this.$store.dispatch('shareContent', { post: this.post });
        },
        async saveEdits() {
            try {
                this.saveInProgress += 1;

                await this.$store.dispatch('updatePost', {
                    post: this.post,
                    updates: {
                        textBody: this.editableText
                    },
                });

                this.isBeingEdited = false;
            } catch (error) {
                console.error(error);
            } finally {
                this.saveInProgress -= 1;
            }
        },
        async toggleEditing() {
            this.isBeingEdited = !this.isBeingEdited;
            // NOTE: $nextTick doesn't work here. I think the menu activator refocuses after it's closed.
            await new Promise(resolve => setTimeout(resolve, 50));
            if (this.isBeingEdited) {
                console.log('FIELD', ((this.$refs.editField as Vue)?.$el as HTMLTextAreaElement)?.focus);
                ((this.$refs.editField as Vue)?.$el as HTMLTextAreaElement)?.focus();
            }
        },
        async deletePost() {
            this.isBeingDeleted = true;
            const { error } = await this.$store.dispatch('deletePost', { id: this.post.id });
            if (error) {
                this.showingDeleteDialog = false;
            } else {
                if (this.expandedView) {
                    let redirectRoute = { name: RouteNames.POSTS };
                    if (this.$store.state.previousRoutes.length !== 0) {
                        redirectRoute = this.$store.state.previousRoutes[this.$store.state.previousRoutes.length - 1];
                    }
                    this.$router.replace(redirectRoute);
                }
            }
        },
    },
});
</script>
<style lang="postcss" scoped>
.post-card {
    background: #fff;
    border-radius: 24px;
    box-shadow: 0px 8px 24px 0px rgba(151, 151, 151, 0.13);
    margin: 1rem 0;
    max-width: 500px;
    padding: 0.1px 0; /* Contain margined children. */
    width: 100%;
}

.sighting-author {
    align-items: center;
    display: flex;
    font-size: var(--type-small);
    padding: var(--spacing-4);
    position: relative;
    transform: translateZ(0); /* Work around a horizontal scrolling bug. */
    z-index: 2;
}

.sighting-author.trends {
    background: linear-gradient(to right bottom, #A2CD3D66, #44ACAD66);
}

.sighting-author :deep(a) {
    text-decoration: none;
}

.author-avatar {
    border-radius: 50%;
    display: block;
    margin-right: 1em;
    object-fit: cover;
    object-position: top;
}

.author-name {
    font-size: var(--type-base);
    font-weight: 500;
}

.sighting-metadata {
    background: var(--color-light-shade);
    display: flex;
    font-size: var(--type-small);
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.location-icon {
    line-height: 0 !important; /* Override Vuetify. */
}

.investigation-label {
    text-decoration: none;
}

.unusual-indicator {
    color: var(--color-danger);
    font-size: 12px;
}

.photo-carousel {
    overflow: visible;
    :deep(.v-carousel__controls__item.v-btn) {
        width: 15px;
        height: 15px;
        padding: 0px;
        margin: 0px;
    }

    :deep(.v-item-group) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    :deep(.v-window__container) {
        margin-bottom: 20px;
    }

    :deep(.v-carousel__controls) {
        position: absolute;
        bottom: -45px;
        z-index: 10;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
    }

    :deep(.v-item-group .v-btn.v-btn--icon) {
        color: #9b9b9b;
        &:before {
            content: none;
        }
        .v-ripple__container {
            display: none;
        }
    }
    :deep(.v-item-group .v-btn.v-btn--icon.v-item--active) {
        color: #4A4A4A;
        &:before {
            content: none;
        }
    }

}

.satellite-image {
    aspect-ratio: 1;
    display: block;
    width: 100%;
}

.gallery :deep(.video-wrapper) {
    display: flex;
    flex-direction: column;
}

.gallery-image {
    height: 100%;
    margin: 0 auto;
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain;
    width: 100%;
}

:deep(.v-expansion-panel:before) {
    content: none;
}
:deep(.v-expansion-panel-header) {
    min-height: auto !important;
}
:deep(.v-expansion-panel-header__icon) {
    margin-left: 0;
    .v-icon {
        color: var(--color-primary) !important;
    }
}
.post-data-title {
    color: var(--color-primary);
    font-weight: 500;
}

.post-data-title .icon {
    margin-top: -2px;
}

.details-toggle {
    color: var(--color-dark-tint);
}

.contextual-data {
    :deep(.v-expansion-panel-content__wrap) {
        background-color: var(--color-light-tint);
        padding: 0px;
        margin: var(--spacing-4) var(--spacing-4);
        margin-top: 0px;
    }
    :deep(.v-tab.v-tab--active) {
        color: #000000;
    }
}
.tab-content {
    padding: var(--spacing-2) var(--spacing-3) var(--spacing-3) var(--spacing-3);
}
.basic-table {
    margin-bottom: var(--spacing-2);
    th {
        text-align: left;
    }
    th, td {
        text-transform: uppercase;
        font-weight: bold;
        color: var(--color-dark);
        font-size: 0.9rem;
        min-width: 50px;
    }

    tbody tr:nth-child(even) {
    }
}
.data-description-text {
    font-style: italic;
    color: var(--color-dark);
    font-size: 0.9rem;
}

[data-truncated] {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.edit-field {
    display: block;
    margin: -1px;
    width: 100%;
}
</style>
