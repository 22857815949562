<template>
    <form class="form" @submit.prevent="handleSubmit">
        <div class="field-outline" />

        <image-preview-list
            v-if="files.length !== 0"
            v-model="files"
            new-design
            class="media-list"
            :accept="acceptedFileTypes"
        />

        <auto-height-text-area
            v-model="content"
            :aria-label="$t('posts.commentInputLabel')"
            :placeholder="$t('posts.commentInputLabel')"
            class="text-field"
        />

        <base-button v-if="files.length === 0" small color="transparent" fab class="initial-media-button" @click="handleMediaButtonClick">
            <add-photo-icon :title="String($t('addMedia'))" />
        </base-button>

        <base-button type="submit" color="primary" small fab :loading="uploadProgress !== -1" :disabled="content.trim() === ''" class="submit-button">
            <send-icon :title="String($t('submit'))" />
        </base-button>

        <div class="progress-bar" :style="`visibility: ${uploadProgress === -1 ? 'hidden' : ''}`">
            <div class="progress-fill" :style="`width: ${uploadProgress / totalMediaSize * 100}%`" />
        </div>
    </form>
</template>

<script lang="ts">
import { openFilePicker } from '@/util.app';
import { defineComponent } from 'vue';
import AutoHeightTextArea from '../AutoHeightTextArea.vue';
import ImagePreviewList from '../ImagePreviewList.vue';
import SendIcon from '../icons/Send.vue';
import AddPhotoIcon from '../icons/AddPhoto.vue';

export default defineComponent({
    components: {
        AddPhotoIcon,
        AutoHeightTextArea,
        ImagePreviewList,
        SendIcon,
    },

    i18n: {
        messages: {
            en: {
                addMedia: 'Add media',
                submit: 'Submit',
                error: 'An error occurred',
            },

            es: {
                addMedia: 'Agregar medios',
                submit: 'Entregar',
                error: 'Ocurrió un error',
            },
        },
    },

    props: {
        postId: { type: String, required: true },
        uniqueId: { type: String, required: true },
    },

    data() {
        return {
            acceptedFileTypes: 'image/*, video/*',
            files: [] as File[],
            content: '',
            uploadProgress: -1,
            error: null as Error | null,
        };
    },

    computed: {
        totalMediaSize() {
            return this.files.map(file => file.size).reduce((a, b) => a + b, 0);
        },
    },

    methods: {
        async handleMediaButtonClick() {
            const files = await openFilePicker(this.acceptedFileTypes, true, true);
            this.files.push(...files);
        },

        async handleSubmit() {
            try {
                this.uploadProgress = 0;

                const mediaIds: string[] = [];

                for (const file of this.files) {
                    let previousChunk = 0;
                    const { data: media } = await this.$store.dispatch('uploadMedia', {
                        file,
                        onUploadProgress: (event: ProgressEvent) => {
                            const { loaded } = event;
                            const currentChunk = loaded - previousChunk;
                            this.uploadProgress += currentChunk;
                            previousChunk = loaded;
                        },
                    });

                    mediaIds.push(media.id);
                }

                const { error } = await this.$store.dispatch('addCommentToPost', {
                    postId: this.postId,
                    uniqueId: this.uniqueId,
                    comment: this.content,
                    mediaIds,
                });

                if (error) throw error;

                this.content = '';
                this.files = [];
            } catch (error) {
                // Errors are handled globally.
                console.error(error);
            } finally {
                this.uploadProgress = -1;
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.form {
    display: grid;
    gap: 0 8px;
    grid-template: auto auto 10px / 1fr 40px 40px;
}

.field-outline {
    background: #F5F6F9;
    border: 2px solid transparent;
    border-radius: 24px;
    grid-area: 1 / 1 / span 2 / span 2;
}

.form:has(.text-field:focus) .field-outline {
    border-color: var(--color-blue-ocean);
}

.media-list {
    grid-area: 1 / 1 / span 1 / span 1;
    padding: 12px;
}

.text-field {
    border: 0;
    grid-area: 2 / 1 / span 1 / span 1;
    min-height: 1em;
    padding: 8px 16px;
    resize: none;
}

.media-list + .text-field {
    padding-top: 0;
}

.text-field:focus {
    outline: none;
}

.initial-media-button {
    align-self: end;
    grid-area: 1 / 2 / span 2 / span 1;
}

.submit-button {
    align-self: end;
    grid-area: 1 / 3 / span 2 / span 1;
}

.progress-bar {
    background: #8888;
    border-radius: 10px;
    grid-area: 3 / 1 / span 1 / span 2;
    overflow: hidden;
    position: relative;
}

.progress-fill {
    background: #888;
    bottom: 0;
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
}
</style>
