<template>
    <overlay-modal :value="true" max-width="50ch" @input="$emit($event ? 'ignore-truthy-event' : 'dismiss')">
        <template #title>{{ $t('title') }}</template>

        <template v-if="!currentUser" #content>
            <p>{{ $t('notSignedIn') }}</p>
        </template>

        <template v-else-if="!submissionSuccessful" #content>
            <v-radio-group v-model="reason">
                <div v-for="[key, label] in Object.entries($t('reasons'))" :key="key">
                    <v-radio :value="label" :label="label" @click.alt="reason = null" />
                    <transition-expand :value="label === reason">
                        <base-text-area v-model="details" class="details-field mt-4">
                            <template #label>{{ $t('details') }}</template>
                        </base-text-area>
                    </transition-expand>
                </div>
            </v-radio-group>
        </template>

        <template v-else #content>
            <p>{{ $t('thanks') }}</p>
        </template>

        <template v-if="!currentUser" #actions>
            <base-button text :disabled="progress !== 0" @click="$emit('dismiss')">{{ $t('cancel') }}</base-button>{{ ' ' }}
            <auth-button color="primary" text>{{ $t('signIn') }}</auth-button>
        </template>

        <template v-else-if="!submissionSuccessful" #actions>
            <base-button text :disabled="progress !== 0" @click="$emit('dismiss')">{{ $t('cancel') }}</base-button>{{ ' ' }}
            <base-button color="primary" text :disabled="!reason" :loading="progress !== 0" @click="handleSubmit">{{ $t('submit') }}</base-button>
        </template>

        <template v-else #actions>
            <base-button text @click="$emit('dismiss')">{{ $t('close') }}</base-button>{{ ' ' }}
        </template>
    </overlay-modal>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import OverlayModal from '@/layouts/OverlayModal.vue';
import { CurrentUser, Post } from '@/types';
import TransitionExpand from './TransitionExpand.vue';
import AuthButton from './AuthButton.vue';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                title: 'Report',
                notSignedIn: 'You must sign in to report a post.',
                reasons: {
                    spam: 'It’s Spam',
                    nudity: 'Nudity or sexual activity',
                    hate: 'Hate speech or symbols',
                    violence: 'Violence or dangerous organizations',
                    illegalSale: 'Sale of illegal or regulated goods',
                    bullying: 'Bullying or harrassment',
                    copywrong: 'Intellectual property violation',
                    suicide: 'Suicide or self-injury',
                },
                details: 'Please add any details we should know',
                cancel: 'Cancel',
                signIn: 'Sign in',
                submit: 'Submit',
                close: 'Close',
                thanks: 'Thanks for your report, someone will look into it soon!',
                error: 'Sorry, something went wrong when sending your report. Please try again!',
            },

            es: {
                title: 'Informe',
                notSignedIn: 'Debes iniciar sesión para denunciar un mensaje.',
                reasons: {
                    spam: 'Es Spam',
                    nudity: 'Desnudez o actividad sexual',
                    hate: 'Discursos o símbolos de odio',
                    violence: 'Organizaciones violentas o peligrosas',
                    illegalSale: 'Venta de productos ilegales o regulados',
                    bullying: 'Intimidación o acoso',
                    copywrong: 'Violación de la propiedad intelectual',
                    suicide: 'Suicidio o autolesiones',
                },
                details: 'Por favor, añada cualquier detalle que debamos conocer',
                cancel: 'Cancelar',
                signIn: 'Acceder',
                submit: 'Enviar',
                close: 'Cerrar',
                thanks: 'Gracias por su informe, ¡alguien lo investigará pronto!',
                error: 'Lo sentimos, algo ha ido mal al enviar su informe. Por favor, inténtelo de nuevo.',
            },
        },
    },

    components: {
        OverlayModal,
        TransitionExpand,
        AuthButton,
    },

    props: {
        postId: {
            type: String as () => Post['id'],
            required: true,
        },
    },

    data() {
        return {
            progress: 0,
            reason: null,
            details: '',
            submissionSuccessful: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },

    methods: {
        async handleSubmit() {
            try {
                this.progress += 1;
                const reason = [this.reason, this.details].filter(Boolean).join(' • ');
                const { error } = await this.$store.dispatch('reportPost', { postId: this.postId, reason });
                if (error) throw error;
                this.submissionSuccessful = true;
            } catch (error) {
                console.error(error);
                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: this.$t('error'),
                });
            } finally {
                this.progress -= 1;
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.details-field :deep(textarea) {
    border: 1px solid #8888;
    margin-top: var(--spacing-2);
}
</style>
