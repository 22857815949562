<template>
    <div class="comment-root">
        <component :is="currentUser ? 'router-link' : 'span'" :to="{ name: 'user.detail', params: { id: comment.userObj.id || '...' } }" class="author-avatar-container" aria-hidden="true">
            <img v-if="comment.userObj.avatar" :src="comment.userObj.avatar | formatImage(32, 32)" alt="" class="author-avatar" width="32" height="32">
        </component>

        <div class="content-container">
            <div>
                <component :is="currentUser ? 'router-link' : 'span'" :to="{ name: 'user.detail', params: { id: comment.userObj.id || '...' } }" class="author-name">
                    {{ comment.userObj.firstName }} {{ currentUser ? comment.userObj.lastName : comment.userObj.lastName.slice(0, 1) }}
                </component>

                <span v-if="comment.userObj.clientGroups && comment.userObj.clientGroups.length > 0" class="author-group">
                    <span class="bullet">•</span>
                    {{ comment.userObj.clientGroups[0].name }}
                </span>

                <template v-else>
                    <span class="bullet">•</span>
                    {{ comment | postShortAddress }}
                </template>

                <span class="bullet">•</span>

                <span :title="comment.updatedAt ? `Edited ${comment.updatedAt}` : comment.createdAt">
                    {{ comment.observedAt | fromNowDate }}<!--
                    --><template v-if="comment.updatedAt">*</template>
                </span>

                <template v-if="currentUser && currentUser.id === comment.userObj.id && !editing">
                    <span class="bullet">•</span>
                    <base-button faux-link style="text-decoration: none;" @click="toggleEditing">Edit</base-button>
                </template>
            </div>

            <form v-if="editing" @keydown.esc="editing = false" @submit.prevent="handleSubmit">
                <auto-height-text-area ref="editField" v-model="draftText" :disabled="saving !== 0" class="edit-field" />

                <div class="text-center my-2 d-flex">
                    <base-button type="submit" :loading="saving !== 0 && !showingDeleteDialog" color="primary">
                        <template v-if="draftText.trim() === ''">Delete</template>
                        <template v-else>Save</template>
                    </base-button>

                    <base-button faux-link class="ml-4" style="text-decoration: none;" @click="toggleEditing">Cancel</base-button>

                    <v-spacer />

                    <base-button faux-link style="text-decoration: none;" @click="showingDeleteDialog = true">Delete</base-button>
                </div>
            </form>

            <markdown-output v-else :value="comment.textBody" basic-formatting />

            <slippy-carousel
                v-if="comment.media && comment.media.length !== 0"
                :prev-next-arrows="comment.media && comment.media.length > 1"
                fit-each-panel
                floating-arrows
                style="aspect-ratio: 1;  border-radius: 8px;"
            >
                <v-lazy v-for="mediaItem in comment.media" :key="mediaItem.id">
                    <!-- the Keep style inline here, a class won't work with videos for some reason. -->
                    <universal-image
                        :src="mediaItem"
                        alt=""
                        :tab-index="0"
                        style="aspect-ratio: 1; display: block; height: auto; width: 100%;"
                    />
                </v-lazy>
            </slippy-carousel>
        </div>

        <v-dialog v-model="showingDeleteDialog" width="40ch">
            <v-card>
                <v-card-title>Delete comment</v-card-title>

                <v-card-text>
                    <p>Are you sure you want to delete this comment?</p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <base-button text @click="showingDeleteDialog = false">Cancel</base-button>
                    <base-button text color="error" :loading="saving !== 0" @click="actuallyDelete">Delete it</base-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import { Comment, CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import AutoHeightTextArea from '../AutoHeightTextArea.vue';
import SlippyCarousel from '../SlippyCarousel.vue';
import UniversalImage from '../UniversalImage.vue';

export default Vue.extend({
    components: {
        AutoHeightTextArea,
        MarkdownOutput,
        SlippyCarousel,
        UniversalImage,
    },

    props: {
        comment: {
            type: Object as () => Comment,
            required: true,
        },
    },

    data() {
        return {
            editing: false,
            draftText: '',
            showingDeleteDialog: false,
            saving: 0,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },

    methods: {
        async toggleEditing() {
            if (this.editing) {
                this.editing = false;
            } else {
                if (this.draftText === '') {
                    this.draftText = this.comment.textBody;
                }

                this.editing = true;

                await this.$nextTick();

                const editField = this.$refs.editField as Vue | null;
                if (editField?.$el instanceof HTMLElement) {
                    editField.$el.focus();
                }
            }
        },

        async handleSubmit() {
            if (this.draftText.trim() === '') {
                this.showingDeleteDialog = true;
            } else {
                this.saveChanges();
            }
        },

        actuallyDelete() {
            this.draftText = '';
            this.saveChanges();
        },

        async saveChanges() {
            try {
                this.saving += 1;

                const { error } = await this.$store.dispatch('updateComment', {
                    comment: this.comment,
                    updates: { textBody: this.draftText },
                });

                if (error) {
                    throw error;
                }

                this.editing = false;
                this.draftText = '';
            } catch (error) {
                console.error(error);
                this.$store.dispatch('alertUser', { type: 'error', message: 'Could not update comment' });
            } finally {
                this.saving -= 1;
                this.showingDeleteDialog = false;
            }
        },
    }
});
</script>

<style lang="postcss" scoped>
.comment-root {
    align-items: flex-start;
    display: flex;
    word-break: break-word;
}

.author-avatar-container {
    flex-shrink: 0;
    margin-right: var(--spacing-2);
    margin-top: 0.3ch;
}

.author-avatar {
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.content-container {
    flex: 1;
}

.author-name,
.author-group {
    font-weight: 500;
    text-decoration: none;
}

.bullet {
    color: var(--color-medium);
    margin: 0 0.3ch;
}

.edit-field {
    display: block;
    margin: -1px;
    width: 100%;
}
</style>
